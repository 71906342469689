.rightHeroContent {
    background: #45adff;
    border-radius: 0 0 50% 0;
}

.bg01 {
    background-color: #8bccff;
}

.bg02 {
    background-color: #66bcff;
}

.bg03 {
    background-color: #37a7ff;
}

.bg04 {
    background-color: #2196f3;
}


.p80 {
    padding: 80px;
    z-index: -99999;
}

.mt-80 {
    margin-top: -80px;
}

.mt80 {
    margin-top: 80px;
}

.mainContentHeight {
    height: 61vh;
}

.radiusCircle {
    border-radius: 50%;
}