.demo-code {
    background: #393939;
    color: white;
    padding: 20px;
    border-radius: 0 0 10px 10px;
}

@media only screen and (max-device-width: 480px) {
    .demo-code {
        width: 93vw;
    }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 767px) {
    .demo-code {
        max-width: 902;
    }
}

@media only screen and (min-device-width: 786px) and (max-device-width: 1040px) {
    .demo-code {
        max-width: 1024;
    }
}