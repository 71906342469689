body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

section {
  margin-top: 5rem;
}

.sample-background {
  padding: 20px;
  border: 1px solid #e5e5e5;
  border-radius: 10px 10px 0 0;
}

.display-flex {
  display: flex;
}

.display-inline-flex {
  display: inline-flex;
}

.align-center {
  align-items: center;
}

.mt-10 {
  margin-top: 10px;
}

.cursor-pointer {
  cursor: pointer
}